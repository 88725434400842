import * as React from "react";
import { GetServerSideProps } from "next";
import { NextPageWithLayout } from "@pages/_app";
import { useRouter } from "next/router";
import Link from "next/link";
import AuthLayout from "@layouts/auth";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@components/button";
import InputPhone from "@components/input-phone";
import { useMutation } from "urql";
import { LoginMutation, LoginMutationVariables } from "@graphql/types";
import { LoginGQL } from "@graphql/mutations/auth.mutation";
import toast from "react-hot-toast";
import useStore from "src/store";
import { withUrqlClient } from "next-urql";
import { getAccessCookie, hasAccessCookie } from "@utils/storage";
import Icon from "@components/icon";
import Head from "next/head";

type LoginInputs = {
  password: string;
};

const Login: NextPageWithLayout = () => {
  const router = useRouter();
  const [nextPage, setNextPage] = React.useState("");
  const [type, setType] = React.useState<"password" | "text">("password");

  React.useEffect(() => {
    if (router.query.next) {
      if (router.query.next === "add-a-service") {
        setNextPage("become-a-vendor/add-a-service");
      } else {
        setNextPage(router.query.next as string);
      }

      router.replace("/login", undefined, { shallow: true });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [phoneNumber, setPhoneNumber] = React.useState("");

  const setAccessToken = useStore((state) => state.setAccessToken);
  const setRefreshToken = useStore((state) => state.setRefreshToken);

  const [loginResult, login] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LoginGQL);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<LoginInputs>({ mode: "onChange" });

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    login({ input: { password: data.password, username: phoneNumber } })
      .then((res) => {
        if (res.error) {
          toast.error(<p className="toast">{res.error.message}</p>);
        }
        if (res.data?.login.access_token) {
          setAccessToken(res.data.login.access_token);
          router.push(`/${nextPage}`);
          toast.success(<p className="toast">Login successful</p>);
        }
        if (res.data?.login.refresh_token) {
          setRefreshToken(res.data.login.refresh_token);
        }
      })
      .catch((error) => toast.error(<p className="toast">{error.message}</p>));
  };

  return (
    <>
      <Head>
        <title>Inawo Vendors | Login</title>
        <meta name="title" content="Inawo Vendors | Login" />
        <meta name="description" content="Login on Inawo Vendors" />
      </Head>
      <div className="max-w-lg mb-12 w-full px-4">
        <div className="px-4 w-full">
          <h1 className="font-libre text-2xl mb-3">Good to see you again!</h1>
          <p className="text-base font-light font-sfpro">
            Sign in to your favorite wedding planning tool
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-start items-start gap-6 mt-8 xs:mt-12 w-full"
          >
            <InputPhone
              placeholder="+234"
              name="Phone"
              label="Phone Number(Whatsapp)"
              value={phoneNumber}
              setValue={setPhoneNumber}
            />
            <div className="flex flex-col justify-start items-start gap-0 w-full">
              <label
                htmlFor="password"
                className="text-sm font-sfpro text-inawo-grey-200"
              >
                Password
              </label>
              <div className="w-full flex justify-start items-center relative">
                <input
                  type={type}
                  placeholder=""
                  className="border-0 border-b text-base py-3 pl-0 border-inawo-grey-300 w-full focus:ring-0 focus:border-inawo-green transition"
                  {...register("password")}
                />{" "}
                <div
                  onClick={() =>
                    setType((prev) =>
                      prev === "password" ? "text" : "password"
                    )
                  }
                  className="absolute top-1/2 transform -translate-y-1/2 right-2 cursor-pointer"
                >
                  <Icon
                    iconName={
                      type === "password"
                        ? "eyeClose"
                        : type === "text"
                        ? "eyeOpen"
                        : "eyeClose"
                    }
                  />
                </div>
              </div>
              {errors.password?.message && (
                <p className="text-red-600 font-sfpro text-sm">
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <p className="text-right font-light text-sm text-inawo-grey ml-auto mb-6">
              <span>
                <Link href="/forgot-password" passHref>
                  <a className="font-normal text-inawo-green">
                    Forgot Password?
                  </a>
                </Link>
              </span>
            </p>
            <Button
              loader={loginResult.fetching || isSubmitting}
              disabled={
                phoneNumber === "" || !isDirty || !isValid || isSubmitting
              }
              type="submit"
              label="Log in "
            >
              Log in
            </Button>
            <p className="text-center font-light text-sm text-inawo-grey mx-auto">
              Don&apos;t have an account?{" "}
              <span>
                <Link href="/register" passHref>
                  <a className="font-medium text-inawo-green">
                    Create an Account
                  </a>
                </Link>
              </span>
            </p>
            <Link href={"/"}>
              <a className="mx-auto -mt-5 font-sfpro text-inawo-black font-light underline text-sm md:text-base">
                Skip, to explore services
              </a>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

Login.getLayout = function getLayout(page: React.ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { req, res } = ctx;
  // send the user to the home page if the access token already exists
  const loggedIn = hasAccessCookie({ req, res });

  if (loggedIn) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

export default withUrqlClient(
  (_ssr) => ({
    url: process.env.NEXT_PUBLIC_API_URL!,
    requestPolicy: "cache-and-network",
    fetchOptions: () => {
      return {
        headers: {
          "x-user-token": getAccessCookie() ? `${getAccessCookie()}` : "",
        },
      };
    },
  }),
  { ssr: false }
)(Login);
